import UsersApi from "../../api/usersApi";

const Users = () => {
  const token = localStorage.getItem("token");
  const fixAvatars = async () => {
    if (!token) return;

    const res = await UsersApi.fixUserAvatars(token);
    console.log(res);
  };
  const buidQuiz = async () => {
    await fetch("http://localhost:5002/tree-quizes/buildQuizes", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };

  return (
    <div style={{ color: "white" }}>
      Users{" "}
      {/* <button onClick={fixAvatars}>Fix avatars</button>
      <button onClick={buidQuiz}>Build quiz</button>*/}
    </div>
  );
};

export default Users;

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ProjectApi from "../../../../../../api/projectApi";
import UsersApi from "../../../../../../api/usersApi";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/Companies/delete.svg";
import { ReactComponent as TokenIcon } from "../../../../../../assets/General/heart-tick.svg";
import CircleAvatar from "../../../../../../components/CircleAvatar/CircleAvatar";
import ConfirmationModal from "../../../../../../components/ConfirmationModal/ConfirmationModal";
import { TypeCompanyEmployee } from "../../../../../../types/Company";
import { SuccesNotify } from "../../../../../../utils/toaster";
import s from "./SpecialistCardBlock.module.css";

type SpecialistCardBlockProps = {
  userId: string;
  bgColor: string;
  discount: number;
  projectId: string;
  setProjectSpecialists: Dispatch<SetStateAction<TypeCompanyEmployee[]>>;
  isInviteCard?: boolean;
  specialistsToInvite: string[];
  setSpecialistsToInvite: Dispatch<SetStateAction<string[]>>;
};

type ProgramUserData = {
  name: string;
  surname: string;
  price: number;
  specializationsData: { specialistId: string; specialization: string }[];
};

const SpecialistCardBlock = ({
  userId,
  bgColor,
  discount,
  projectId,
  setProjectSpecialists,
  isInviteCard,
  specialistsToInvite,
  setSpecialistsToInvite,
}: SpecialistCardBlockProps) => {
  const token = localStorage.getItem("token");
  const [specialistData, setSpecialistData] = useState<ProgramUserData | null>(
    null
  );
  const [actionLoaders, setActionLoaders] = useState({
    laodUserData: false,
    removeSpecLoader: false,
  });
  const [deleteSpecModal, setDeleteSpecModal] = useState(false);
  const [selectSpecialist, setSelectSpecialist] = useState<{
    toAdd: string | undefined;
    toRemove: string | undefined;
  }>({
    toAdd: undefined,
    toRemove: undefined,
  });

  useEffect(() => {
    if (selectSpecialist.toRemove) {
      setSpecialistsToInvite((prev) =>
        prev.filter((el) => el !== selectSpecialist.toRemove)
      );
    }
    if (selectSpecialist.toAdd) {
      setSpecialistsToInvite((prev) => [...prev, selectSpecialist.toAdd!]);
    }
  }, [selectSpecialist]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token) return;
      const selectUserFields = "name surname email specialistIds";
      setActionLoaders((prev) => ({ ...prev, laodUserData: true }));
      const response = await UsersApi.getUserSelectedFields(
        token,
        userId,
        selectUserFields,
        "price mainSpecializations"
      );

      if (
        response.status &&
        response.userData &&
        response.userData.specialistIds
      ) {
        const responseUserData = {
          name: response.userData.name!,
          surname: response.userData.surname!,

          specializationsData: response.userData.specialistIds.map((item) => ({
            specialization: item.mainSpecializations[0].specialization,
            specialistId: item._id,
          })),
          price: response.userData.specialistIds[0].price!,
        };
        setSpecialistData(responseUserData);
      }
      setActionLoaders((prev) => ({ ...prev, laodUserData: false }));
    };
    makeAsync();
  }, [token]);

  const removeSpecFromProject = async () => {
    if (!token) return;
    const payload = { userIdToRemove: userId, projectId: projectId };
    setActionLoaders((prev) => ({ ...prev, removeSpecLoader: true }));
    const response = await ProjectApi.removeUserFromProject(token, payload);
    setActionLoaders((prev) => ({ ...prev, removeSpecLoader: false }));
    if (response.status) {
      setDeleteSpecModal(false);
      SuccesNotify("Specialist has been removed from project");
      setProjectSpecialists((prev) =>
        prev.filter((spec) => spec.user._id !== userId)
      );
    }
  };

  return (
    <div className={s.container} style={{ backgroundColor: bgColor }}>
      <div className={s.mainSpecInfoBlock}>
        <CircleAvatar userId={userId} width={"50px"} height={"50px"} />
        <div className={s.mainSpecTitleInfo}>
          <span>
            {specialistData &&
              specialistData.name + " " + specialistData.surname}
          </span>
        </div>{" "}
      </div>
      <div className={s.specalizationTitleBlock}>
        {specialistData &&
          specialistData?.specializationsData.map((item, index) => (
            <div
              key={index}
              className={
                specialistsToInvite.includes(item.specialistId)
                  ? s.activeSpecialization
                  : s.notActiveSpecialiation
              }
              onClick={() =>
                setSelectSpecialist((prev) => ({
                  ...prev,
                  toAdd:
                    prev.toAdd === item.specialistId
                      ? undefined
                      : item.specialistId,
                  toRemove:
                    prev.toAdd === item.specialistId ||
                    (prev.toAdd && prev.toAdd !== item.specialistId)
                      ? prev.toAdd
                      : undefined,
                }))
              }
            >
              {item.specialization}
            </div>
          ))}
      </div>

      {specialistData && (
        <div className={s.priceActionBlock}>
          <div className={s.prcieBlock}>
            <span>
              {discount === 100
                ? 0
                : discount === 0
                ? specialistData.price
                : (
                    specialistData.price -
                    specialistData.price * (discount / 100)
                  ).toFixed(1)}
              <TokenIcon className={s.starIcon} />
            </span>
            <span>{specialistData.price}</span>
          </div>
          {!isInviteCard && (
            <div className={s.actionBlock}>
              <div
                className={s.deleteBtn}
                role="button"
                onClick={() => setDeleteSpecModal(true)}
              >
                <DeleteIcon className={s.deleteIcon} />
              </div>
            </div>
          )}
        </div>
      )}
      {specialistData &&
        specialistsToInvite.some((item) =>
          specialistData.specializationsData
            .map((item) => item.specialistId)
            .includes(item)
        ) && <div className={s.indicator}></div>}
      <ConfirmationModal
        confirmFunc={removeSpecFromProject}
        confirmTitle={`Are you sure you want to remove ${
          specialistData?.name + " " + specialistData?.surname
        } from this project?`}
        modalVisible={deleteSpecModal}
        setModalVisible={setDeleteSpecModal}
        loader={actionLoaders.removeSpecLoader}
      />
    </div>
  );
};

export default SpecialistCardBlock;

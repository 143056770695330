import { Dispatch, SetStateAction, useContext, useState } from "react";
import AnimateHeight from "react-animate-height";
import SpecialistApi from "../../../api/specialistsApi";
import { ReactComponent as ChevronDownIcon } from "../../../assets/General/chevronDown.svg";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import { ContextProvider } from "../../../contextProvider";
import { SpecialistLevels } from "../../../types/Subscription";
import { SpecialistData } from "../../../types/TypeSpecialist";
import { TypeUserData } from "../../../types/TypeUsers";
import Profile from "../../Profile/Profile";
import s from "./SpecialistSettings.module.css";

type SpecialistSettingsProps = {
  selectedSpecialist: SpecialistData | undefined;
  setSeletedSpecialityInfo: Dispatch<
    SetStateAction<SpecialistData | undefined>
  >;
  setSelectedSpecialist: Dispatch<SetStateAction<TypeUserData | undefined>>;
  setSpecialistData: Dispatch<SetStateAction<TypeUserData[] | undefined>>;
  setIsUpdatedProfileModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsPublicProfileModalOpen: Dispatch<SetStateAction<boolean>>;
  getPublicInfoString: string;
  userId: string;
};

const SpecialistSettings = ({
  selectedSpecialist,
  setSelectedSpecialist,
  setSpecialistData,
  setIsUpdatedProfileModalOpen,
  setIsPublicProfileModalOpen,
  setSeletedSpecialityInfo,
  getPublicInfoString,
  userId,
}: SpecialistSettingsProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [selectStatusCollapsed, setSelectStatusCollapsed] = useState({
    verification: true,
    subscriptions: true,
    level: true,
    changes: true,
  });
  const [diffModal, setDiffModal] = useState(false);

  const changeVerifuStatusHandler = async (status: boolean) => {
    if (!token || !userData || !selectedSpecialist) return;
    const response = await SpecialistApi.update(
      { _id: selectedSpecialist._id, isVerified: status },
      token
    );
    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, verification: true }));
      setSeletedSpecialityInfo((prev) => ({ ...prev!, isVerified: status }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistIds) return prev;
        return {
          ...prev,
          specialistIds: prev.specialistIds.map((item) => {
            if (item._id === selectedSpecialist._id)
              return { ...item, isVerified: status };
            else return item;
          }),
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(selectedSpecialist._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === selectedSpecialist._id)
                  return { ...item, isVerified: status };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
  };

  const changeVerifyProfileStatus = async (status: boolean) => {
    if (!token || !userData || !selectedSpecialist) return;

    const response = await SpecialistApi.update(
      { _id: selectedSpecialist._id, isVerifiedChanges: status },
      token
    );

    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, changes: true }));
      setSeletedSpecialityInfo((prev) => ({
        ...prev!,
        isVerifiedChanges: status,
      }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistIds) return prev;
        return {
          ...prev,
          specialistIds: prev.specialistIds.map((item) => {
            if (item._id === selectedSpecialist._id)
              return { ...item, isVerifiedChanges: status };
            else return item;
          }),
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(selectedSpecialist._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === selectedSpecialist._id)
                  return { ...item, isVerifiedChanges: status };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
  };
  const changeShowInSubscriptionStatusHandler = async (status: boolean) => {
    if (!token || !userData || !selectedSpecialist) return;

    const response = await SpecialistApi.update(
      { _id: selectedSpecialist._id, showInSubscriptions: status },
      token
    );
    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, subscriptions: true }));
      setSeletedSpecialityInfo((prev) => ({
        ...prev!,
        showInSubscriptions: status,
      }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistIds) return prev;
        return {
          ...prev,
          specialistIds: prev.specialistIds.map((item) => {
            if (item._id === selectedSpecialist._id)
              return { ...item, showInSubscriptions: status };
            else return item;
          }),
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(selectedSpecialist._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === selectedSpecialist._id)
                  return { ...item, showInSubscriptions: status };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
  };
  const changeLevelHandler = async (level: SpecialistLevels) => {
    if (!token || !userData || !selectedSpecialist) return;

    const response = await SpecialistApi.update(
      { _id: selectedSpecialist._id, level: level },
      token
    );
    if (response.status) {
      setSelectStatusCollapsed((prev) => ({ ...prev, level: true }));
      setSeletedSpecialityInfo((prev) => ({ ...prev!, level: level }));
      setSelectedSpecialist((prev) => {
        if (!prev || !prev.specialistIds) return prev;
        return {
          ...prev,
          specialistIds: prev.specialistIds.map((item) => {
            if (item._id === selectedSpecialist._id)
              return { ...item, level: level };
            else return item;
          }),
        };
      });
      setSpecialistData((prevData) => {
        if (!prevData) return prevData;

        return prevData.map((specialist) => {
          if (
            specialist.specialistIds &&
            specialist.specialistIds
              .map((item) => item._id)
              .includes(selectedSpecialist._id)
          ) {
            return {
              ...specialist,
              specialistIds: specialist.specialistIds.map((item) => {
                if (item._id === selectedSpecialist._id)
                  return { ...item, level: level };
                else return item;
              }),
            };
          }
          return specialist;
        });
      });
    }
  };
  return (
    <div className={s.settingsBlock}>
      <div>
        <div className={s.statusBlockTitle}>
          <span></span>
          <span>Verification status</span>
        </div>
        <div className={s.selectStatusBlock}>
          <div
            className={s.cuurentVerifyStatusBlock}
            style={
              selectStatusCollapsed.verification
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "5px",
                  }
            }
            onClick={() =>
              setSelectStatusCollapsed((prev) => ({
                ...prev,
                verification: !prev.verification,
              }))
            }
          >
            <span
              className={
                selectedSpecialist?.isVerified
                  ? s.verifiedColumn
                  : s.unverifiedColumn
              }
            >
              {selectedSpecialist?.isVerified ? "Verified" : "Not verified"}
            </span>
            <ChevronDownIcon
              className={
                selectStatusCollapsed.verification
                  ? s.chevronDownIcon
                  : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatusCollapsed((prev) => ({
                  ...prev,
                  verification: !prev.verification,
                }));
              }}
            />
          </div>
          <AnimateHeight
            height={selectStatusCollapsed.verification ? 0 : "auto"}
            duration={500}
            className={s.animHeightStatus}
          >
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeVerifuStatusHandler(true)}
            >
              <span className={s.verifiedColumn}>Verified</span>
            </div>
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeVerifuStatusHandler(false)}
            >
              <span className={s.unverifiedColumn}>Not verified</span>
            </div>
          </AnimateHeight>
        </div>
      </div>
      <div>
        <div className={s.statusBlockTitle}>
          <span></span>
          <span>Participation in subscriptions</span>
        </div>
        <div className={s.selectStatusBlock}>
          <div
            className={s.cuurentVerifyStatusBlock}
            style={
              selectStatusCollapsed.subscriptions
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "5px",
                  }
            }
            onClick={() =>
              setSelectStatusCollapsed((prev) => ({
                ...prev,
                subscriptions: !prev.subscriptions,
              }))
            }
          >
            <span
              className={
                selectedSpecialist?.showInSubscriptions
                  ? s.verifiedColumn
                  : s.unverifiedColumn
              }
            >
              {selectedSpecialist?.showInSubscriptions
                ? "Participates"
                : "Does not participate"}
            </span>
            <ChevronDownIcon
              className={
                selectStatusCollapsed.verification
                  ? s.chevronDownIcon
                  : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatusCollapsed((prev) => ({
                  ...prev,
                  subscriptions: !prev.subscriptions,
                }));
              }}
            />
          </div>
          <AnimateHeight
            height={selectStatusCollapsed.subscriptions ? 0 : "auto"}
            duration={500}
            className={s.animHeightStatus}
          >
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeShowInSubscriptionStatusHandler(true)}
            >
              <span className={s.verifiedColumn}>Participates</span>
            </div>
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeShowInSubscriptionStatusHandler(false)}
            >
              <span className={s.unverifiedColumn}>Does not participate</span>
            </div>
          </AnimateHeight>
        </div>
      </div>
      <div>
        <div className={s.statusBlockTitle}>
          <span></span>
          <span>Level</span>
        </div>
        <div className={s.selectStatusBlock}>
          <div
            className={s.cuurentVerifyStatusBlock}
            style={
              selectStatusCollapsed.level
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "5px",
                  }
            }
            onClick={() =>
              setSelectStatusCollapsed((prev) => ({
                ...prev,
                level: !prev.level,
              }))
            }
          >
            <span
              className={
                selectedSpecialist?.level
                  ? s.verifiedColumn
                  : s.unverifiedColumn
              }
            >
              {selectedSpecialist?.level
                ? selectedSpecialist?.level.toLowerCase()
                : "No level"}
            </span>
            <ChevronDownIcon
              className={
                selectStatusCollapsed.level
                  ? s.chevronDownIcon
                  : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatusCollapsed((prev) => ({
                  ...prev,
                  level: !prev.level,
                }));
              }}
            />
          </div>
          <AnimateHeight
            height={selectStatusCollapsed.level ? 0 : "auto"}
            duration={500}
            className={s.animHeightStatus}
          >
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeLevelHandler(SpecialistLevels.STUDENT)}
            >
              <span className={s.verifiedColumn}>
                {SpecialistLevels.STUDENT.toLowerCase()}
              </span>
            </div>
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() =>
                changeLevelHandler(SpecialistLevels.GRADUATED_STUDENT)
              }
            >
              <span className={s.verifiedColumn}>
                {SpecialistLevels.GRADUATED_STUDENT.toLowerCase()}
              </span>
            </div>
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeLevelHandler(SpecialistLevels.LEVEL1)}
            >
              <span className={s.verifiedColumn}>
                {SpecialistLevels.LEVEL1.toLowerCase()}
              </span>
            </div>
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeLevelHandler(SpecialistLevels.LEVEL2)}
            >
              <span className={s.verifiedColumn}>
                {SpecialistLevels.LEVEL2.toLowerCase()}
              </span>
            </div>
          </AnimateHeight>
        </div>
      </div>
      <div className={s.profileChangeStatusBlock}>
        <div className={s.profileVerifyStatusTitleBlock}>
          <div className={s.statusBlockTitle}>
            <span></span>
            <span>Profile changes status</span>
          </div>
          <div className={s.openProfileBtnsBlock}>
            <span style={{ color: "white" }}>View</span>
            <div
              className={s.viewUpdatedProfileBtn}
              role="button"
              onClick={() => setIsPublicProfileModalOpen(true)}
            >
              current
            </div>
            <div
              className={s.viewUpdatedProfileBtn}
              role="button"
              onClick={() => setIsUpdatedProfileModalOpen(true)}
            >
              updated
            </div>
            <div
              className={s.viewUpdatedProfileBtn}
              role="button"
              onClick={() => setDiffModal(true)}
            >
              difference
            </div>
            <span style={{ color: "white" }}>profile</span>
          </div>
        </div>
        <div className={s.selectStatusBlock}>
          <div
            className={s.cuurentVerifyStatusBlock}
            style={
              selectStatusCollapsed.changes
                ? {}
                : {
                    borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                    paddingBottom: "5px",
                  }
            }
            onClick={() =>
              setSelectStatusCollapsed((prev) => ({
                ...prev,
                changes: !prev.changes,
              }))
            }
          >
            <span
              className={
                selectedSpecialist?.isVerifiedChanges
                  ? s.verifiedColumn
                  : s.unverifiedColumn
              }
            >
              {selectedSpecialist?.isVerifiedChanges
                ? "Verified"
                : "Not verified"}
            </span>
            <ChevronDownIcon
              className={
                selectStatusCollapsed.changes
                  ? s.chevronDownIcon
                  : s.chevronUpIcon
              }
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatusCollapsed((prev) => ({
                  ...prev,
                  changes: !prev.changes,
                }));
              }}
            />
          </div>
          <AnimateHeight
            height={selectStatusCollapsed.changes ? 0 : "auto"}
            duration={500}
            className={s.animHeightStatus}
          >
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeVerifyProfileStatus(true)}
            >
              <span className={s.verifiedColumn}>Verified</span>
            </div>
            <div
              className={s.selectStatusElement}
              role="button"
              onClick={() => changeVerifyProfileStatus(false)}
            >
              <span className={s.unverifiedColumn}>Not verified</span>
            </div>
          </AnimateHeight>
        </div>
      </div>
      <ModalWindow
        isOpen={diffModal}
        setIsOpen={setDiffModal}
        height="90vh"
        width="90vw"
        padding="0"
      >
        <div className={s.profileContainer}>
          {selectedSpecialist && (
            <Profile
              userId={userId}
              isOpen={diffModal}
              setIsOpen={setDiffModal}
              getPublicInfoString={getPublicInfoString}
              isPublicProfile={true}
              initialSpecialization={selectedSpecialist!._id}
              showDiff
            />
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default SpecialistSettings;

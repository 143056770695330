import { ProjectsAvailableSessionTypes } from "./Projects";
import { TypeUserData } from "./TypeUsers";

export type TypeNewCompany = {
  name: string;
  description?: string;
  avatar?: string;
  clients?: string[];
};

export type Company = {
  _id: string;
  name: string;
  description?: string;
  avatar?: string;
  owner: TypeUserData;
  managers: TypeUserData[];
  clients: TypeUserData[];
  specialists: TypeUserData[];
  projects: Project[];
};

export type Project = {
  _id: string;
  title: string;
  description: string;
  isPrivate: boolean;
  avatar?: string;
  creationDate?: Date;
  endDate?: Date;
  clientLimit: number;
  specialistLimit: number;
  clients: { _id: string; name: string; surname: string }[];
  specialists: { _id: string; name: string; surname: string }[];
  sessionDiscount: number;
  realTimeSessionDiscount: number;
  packageDiscount: number;
  video?: string;
  availableSessionTypes?: {
    type: ProjectsAvailableSessionTypes;
    amount: number;
    unlimited: boolean;
  }[];
};
export type TypeCompanyEmployee = {
  user: {
    name: string;
    surname: string;
    _id: string;
    email: string;
    projects: string[];
  };
  role: { value: string; translatedValue: string };
};
export type TypeCompanyStatistic = {
  employees: number;
  finishedSessions: number;
  futureSessions: number;
};
export enum Roles {
  ADMIN = "ADMIN",
  DEEFAULT_USER = "DEEFAULT_USER",
  SPECIALIST = "SPECIALIST",
  PSYCHOLOGIST = "PSYCHOLOGIST",
  COACH = "COACH",
  MENTOR = "MENTOR",
  MANAGER = "MANAGER",
}
export type MyProjectType = Omit<Project, "clients" | "specialists"> & {
  clients: string[];
  specialists: string[];
};

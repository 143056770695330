import { Tooltip } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useEffect, useState } from "react";
import SpecialistApi from "../../../../api/specialistsApi";
import { ReactComponent as ViewFileIcon } from "../../../../assets/Profile/specialist/view-file.svg";
import ModalWindow from "../../../../components/ModalWindow/ModalWindow";
import { QualificationCertificatesResponse } from "../../../../types/TypeSpecialist";
import { formatEducationPeriod } from "../../../../utils/dateActions";
import s from "./Certificates.module.css";

type FederationsProps = {
  specialistId: string;
  isOldProfile: boolean;
};

const Qualifications = ({ specialistId, isOldProfile }: FederationsProps) => {
  const token = localStorage.getItem("token");
  const [qualifications, setQualifications] = useState<
    QualificationCertificatesResponse[] | undefined
  >();
  const [previewFileModal, setPreviewFileModal] = useState(false);
  const [previewFile, setPreviewFile] = useState<{
    pdfFile: string | null;
    imageFile: string | null;
  }>({ pdfFile: null, imageFile: null });

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const getQualificationCertificates = async () => {
    if (!token) return;
    const response = await SpecialistApi.getQualificationCertificates(
      token,
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };
  const getQualificationPublicCertificates = async () => {
    const response = await SpecialistApi.getQualificationPublicCertificates(
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };

  const previewFileHandler = (link: string, type: string) => {

    if (type.includes("pdf")) {
      setPreviewFile({
        imageFile: null,
        pdfFile: link,
      });
    } else {
      setPreviewFile({
        imageFile: link,
        pdfFile: null,
      });
    }
    setPreviewFileModal(true);
  };

  useEffect(() => {
    const makeAsync = async () => {
      try {
        const getQualificationCertificatesResponse = !isOldProfile
          ? await getQualificationCertificates()
          : await getQualificationPublicCertificates();

        setQualifications(getQualificationCertificatesResponse?.certificates);
      } catch (error) {
        console.error("Error fetching certificates data:", error);
      } finally {
      }
    };
    makeAsync();
  }, [specialistId]);

  useEffect(() => {
    setPreviewFile((prev) => ({ ...prev, imageFile: null }));
  }, []);

  return (
    <div className={s.container}>
      {qualifications && qualifications.length ? (
        qualifications.map((item, index) => (
          <div
            key={index}
            className={s.certificateElement}
            style={{
              backgroundColor: isOldProfile
                ? "rgb(185, 110, 110)"
                : "rgb(106, 148, 102)",
            }}
          >
            <div className={s.headCertificateBlock}>
              <a href={item.qualification.badge.url} target="_blank">
                <img
                  src={item.qualification.badge.link}
                  alt=""
                  className={s.badgeIcon}
                />
              </a>
              <span>{item.qualification.text}</span>
            </div>
            <div className={s.bottomCertificateBlock}>
              <span>
                {formatEducationPeriod(
                  new Date(item.membershipStart),
                  new Date(item.membershipEnd),
                  "en"
                )}
              </span>
              <Tooltip arrow title={"Show certificate"}>
                <div
                  onClick={() => previewFileHandler(item.link, item.type)}
                  className={s.showCertificateBtn}
                >
                  <ViewFileIcon className={s.viewCertificateIcon} />
                </div>
              </Tooltip>
            </div>
          </div>
        ))
      ) : (
        <h3>No certificates</h3>
      )}
      <ModalWindow
        isOpen={previewFileModal}
        setIsOpen={setPreviewFileModal}
        bgColor="transparent"
        height="fit-content"
        width={"fit-content"}
        padding="0"
        maxWidth="fit-content"
      >
        <div
          className={
            previewFile.pdfFile ? s.pdfPreviewBlock : s.imagePreviewBlock
          }
        >
          {previewFile.pdfFile && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
              <div className={s.pdfWorker}>
                <Viewer
                  fileUrl={previewFile.pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          )}

          {previewFile.imageFile && (
            <img src={previewFile.imageFile} alt="" className={s.previewImg} />
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default Qualifications;

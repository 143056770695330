import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { ReactComponent as CloseIcon } from "../../../assets/Companies/delete.svg";
import { ReactComponent as ChevronDown } from "../../../assets/General/chevronDown.svg";
import { ReactComponent as PlusIcon } from "../../../assets/General/plus.svg";
import Input from "../../../components/Input/Input";
import Loader from "../../../components/Loader/Loader";
import MuiSwitch from "../../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../components/StandartButton/StandartButton";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextarea";
import { PaymentForType, SpecialistLevels } from "../../../types/Subscription";
import { createSubscription } from "../../../utils/subscriptions";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import s from "./NewSubscription.module.css";
import SubscriptionInfoCard from "./SubscriptionInfoCard/SubscriptionInfoCard";

const languages = [
  { value: "en", lable: "English" },
  { value: "uk", lable: "Українська" },
  { value: "ru", lable: "Русский" },
];

type NewSubscriptionProps = {
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};

type Language = "en" | "uk" | "ru";

export interface TranslatableText {
  text: string | undefined;
  language: Language;
}

export interface Feature {
  title: TranslatableText[];
  description: TranslatableText[];
  icon?: File;
}

export type InfoCard = {
  title: TranslatableText[];
  description: TranslatableText[];
  icon?: File;
  features: Feature[];
};
type NewSubscription = {
  title: TranslatableText[];
  description: TranslatableText[];
  costPerMonth: number;
  discount: {
    for: string;
    amount: number;
  }[];
  accessibleLevels: string[];
  discountsForSubscription: {
    days: number;
    amount: number;
  }[];
  isOn: boolean;
};
const initialNewSubscription: NewSubscription = {
  title: [
    { text: undefined, language: "en" },
    { text: undefined, language: "uk" },
    { text: undefined, language: "ru" },
  ],
  description: [
    { text: undefined, language: "en" },
    { text: undefined, language: "uk" },
    { text: undefined, language: "ru" },
  ],
  costPerMonth: 0,
  discount: [{ for: "", amount: 0 }],
  accessibleLevels: [],
  discountsForSubscription: [
    { days: 30, amount: 0 },
    { days: 90, amount: 0 },
    { days: 180, amount: 0 },
  ],
  isOn: false,
};

const specLevels = {
  [SpecialistLevels.STUDENT]: "Beginner",
  [SpecialistLevels.GRADUATED_STUDENT]: "Practitioner",
  [SpecialistLevels.LEVEL1]: "Professional",
  [SpecialistLevels.LEVEL2]: "Master",
};
const sessionsDiscount = {
  [PaymentForType.REAL_TIME_SESSION]: "Real time session",
  [PaymentForType.SESSION]: "Session",
  [PaymentForType.SESSION_PACKAGE]: "Session`s packages",
};

const specLevelsData = [
  { lable: "Beginner", value: SpecialistLevels.STUDENT },
  { lable: "Practitioner", value: SpecialistLevels.GRADUATED_STUDENT },
  { lable: "Professional", value: SpecialistLevels.LEVEL1 },
  { lable: "Master", value: SpecialistLevels.LEVEL2 },
];

const sessionDiscountTypesData = [
  {
    lable: "Real time session",
    value: PaymentForType.SESSION,
  },
  {
    lable: "Session",
    value: PaymentForType.REAL_TIME_SESSION,
  },
  {
    lable: "Session`s packages",
    value: PaymentForType.SESSION_PACKAGE,
  },
];

const subscriptionInfoInitial: InfoCard = {
  title: [
    { text: undefined, language: "en" },
    { text: undefined, language: "uk" },
    { text: undefined, language: "ru" },
  ],
  description: [
    { text: undefined, language: "en" },
    { text: undefined, language: "uk" },
    { text: undefined, language: "ru" },
  ],
  icon: undefined,
  features: [
    {
      title: [
        { text: undefined, language: "en" },
        { text: undefined, language: "uk" },
        { text: undefined, language: "ru" },
      ],
      description: [
        { text: undefined, language: "en" },
        { text: undefined, language: "uk" },
        { text: undefined, language: "ru" },
      ],
      icon: undefined,
    },
  ],
};
const NewSubscription = ({ setModalVisible }: NewSubscriptionProps) => {
  const token = localStorage.getItem("token");
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const [newSubscription, setNewSubscription] = useState(
    initialNewSubscription
  );
  const [selectedLanguages, setSelectedLanguage] = useState("en");
  const [discountForData, setDiscountForData] = useState(
    sessionDiscountTypesData
  );
  const [subscriptionInfo, setSubscriptionInfo] = useState([
    subscriptionInfoInitial,
  ]);
  const [openCards, setOpenCards] = useState<number[]>([]);
  const [actionLoaders, setActionLoaders] = useState({ mainLaoder: false });
  useAutosizeTextArea(
    descriptionRef.current,
    newSubscription.description.find((el) => el.language === selectedLanguages)
      ?.text!
  );

  useEffect(() => {
    const availabelSpecLvlsData = specLevelsData.filter((item) =>
      newSubscription.accessibleLevels.includes(item.value)
    );
    const availabelDiscountForData = [
      ...sessionDiscountTypesData,
      ...availabelSpecLvlsData,
    ];
    //@ts-expect-error
    setDiscountForData(availabelDiscountForData);
  }, [newSubscription]);

  const addNewFeature = (index: number) => {
    const newFeature: Feature = {
      title: [
        { text: "", language: "en" },
        { text: "", language: "uk" },
        { text: "", language: "ru" },
      ],
      description: [
        { text: "", language: "en" },
        { text: "", language: "uk" },
        { text: "", language: "ru" },
      ],
      icon: undefined,
    };

    setSubscriptionInfo((prevState: InfoCard[]) => {
      const newState = [...prevState];
      newState[index].features = [...newState[index].features, newFeature];
      return newState;
    });
  };
  const removeFeature = (index: number, featureIndex: number) => {
    setSubscriptionInfo((prevState: InfoCard[]) => {
      const newState = [...prevState];
      newState[index].features = newState[index].features.filter(
        (_, i) => i !== featureIndex
      );
      return newState;
    });
  };
  const createSubscriptionHandler = async () => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, mainLaoder: true }));
    const newSubscriptionData = {
      title: newSubscription.title.find((el) => el.language === "en")?.text!,
      description: newSubscription.description.find(
        (el) => el.language === "en"
      )?.text!,
      costPerMonth: newSubscription.costPerMonth,
      discount: newSubscription.discount as {
        for: PaymentForType | SpecialistLevels;
        amount: number;
      }[],
      accessibleLevels: newSubscription.accessibleLevels as SpecialistLevels[],
      discountsForSubscription: newSubscription.discountsForSubscription,
      isOn: newSubscription.isOn,
      titleLabels: newSubscription.title,
      descriptionLabels: newSubscription.description,
    };
    const response = await createSubscription(
      token,
      //@ts-expect-error
      newSubscriptionData,
      subscriptionInfo
    );
    setActionLoaders((prev) => ({ ...prev, mainLaoder: false }));
    if (response) {
      SuccesNotify("Subscription has been created");
      setNewSubscription(initialNewSubscription);
      setSubscriptionInfo([subscriptionInfoInitial]);
      setModalVisible(false);
    }
  };

  function validateData(
    subscription: NewSubscription,
    infoCards: InfoCard[]
  ): boolean {
    // Проверка текстовых полей на пустоту или undefined
    const hasValidTexts = (texts: TranslatableText[]): boolean => {
      return texts.every(
        ({ text }) => text !== undefined && text.trim() !== ""
      );
    };

    // Валидация NewSubscription
    const isValidSubscription = () => {
      return (
        hasValidTexts(subscription.title) &&
        hasValidTexts(subscription.description) &&
        subscription.accessibleLevels.length > 0 &&
        subscription.discountsForSubscription.length > 0
      );
    };

    // Валидация InfoCard[]
    const areValidInfoCards = () => {
      return infoCards.every((card) => {
        if (!hasValidTexts(card.title) || !hasValidTexts(card.description)) {
          return false;
        }

        // Проверка каждого Feature внутри InfoCard
        return card.features.every(
          (feature) =>
            hasValidTexts(feature.title) && hasValidTexts(feature.description)
        );
      });
    };

    return isValidSubscription() && areValidInfoCards();
  }

  if (actionLoaders.mainLaoder) {
    return (
      <div className={s.loaderContainer}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.header}>
        <div className={s.selectLangBlock}>
          <span className={s.lable}>Select localization</span>
          <MultipleSelect
            value={selectedLanguages}
            data={languages}
            multiplie={false}
            setValue={(value) => setSelectedLanguage(value as string)}
            width="100%"
          />
        </div>
        <StandartButton
          action={createSubscriptionHandler}
          buttonTitle="Create subscription"
          disabled={!validateData(newSubscription, subscriptionInfo)}
          tooltipTitle={
            validateData(newSubscription, subscriptionInfo)
              ? ""
              : "Please complete all required fields."
          }
        />
      </div>
      <div className={s.contentBlock}>
        <div className={s.leftBlock}>
          <Input
            inputValue={
              newSubscription.title.find(
                (el) => el.language === selectedLanguages
              )?.text ?? ""
            }
            isVisible
            required
            onChangeInput={(value) =>
              setNewSubscription((prev) => ({
                ...prev,
                title: prev.title.map((item) =>
                  item.language === selectedLanguages
                    ? { ...item, text: value }
                    : item
                ),
              }))
            }
            lable="Title"
            maxLength={70}
          />
          <div>
            <span className={s.lable}>Description</span>
            <textarea
              rows={1}
              value={
                newSubscription.description.find(
                  (el) => el.language === selectedLanguages
                )?.text ?? ""
              }
              onChange={(e) =>
                setNewSubscription((prev) => ({
                  ...prev,
                  description: prev.description.map((item) =>
                    item.language === selectedLanguages
                      ? { ...item, text: e.target.value }
                      : item
                  ),
                }))
              }
              className={s.textArea}
              placeholder="Start..."
              ref={descriptionRef}
              maxLength={200}
            />
          </div>
          <div>
            <span className={s.lable}>Cost per month</span>
            <Input
              inputValue={newSubscription.costPerMonth.toString()}
              type="number"
              onChangeInput={(value) =>
                setNewSubscription((prev) => ({
                  ...prev,
                  costPerMonth: Number(value),
                }))
              }
              isVisible
              required
            />
          </div>
          <div>
            <span className={s.lable}>Period discounts</span>
            {newSubscription.discountsForSubscription.map((item, index) => (
              <div className={s.periodDiscountBlock}>
                <span>{item.days + " days"}</span>
                <div className={s.periodDiscountInput}>
                  <Input
                    inputValue={item.amount.toString()}
                    onChangeInput={(value) =>
                      setNewSubscription((prev) => ({
                        ...prev,
                        discountsForSubscription:
                          prev.discountsForSubscription.map((el) =>
                            el.days === item.days
                              ? { ...el, amount: Number(value) }
                              : el
                          ),
                      }))
                    }
                    isVisible
                    required
                    type="number"
                  />
                </div>
              </div>
            ))}
          </div>
          <div>
            <span className={s.lable}>Accesible specialists levels</span>
            <MultipleSelect
              data={specLevelsData}
              value={newSubscription.accessibleLevels}
              multiplie
              setValue={(value) =>
                //@ts-expect-error
                setNewSubscription((prev) => ({
                  ...prev,
                  accessibleLevels: value,
                }))
              }
            />
          </div>
          <div>
            <span className={s.lable}>Is publish subscription</span>
            <MuiSwitch
              checked={newSubscription.isOn}
              setChecked={(value) =>
                setNewSubscription((prev) => ({ ...prev, isOn: value }))
              }
            />
          </div>
          <div>
            <span className={s.lable}>Discounts for</span>
            <div className={s.discountForList}>
              {newSubscription.discount.map((item, index) => (
                <div className={s.discountForElement} key={index}>
                  <div>
                    <MultipleSelect
                      data={discountForData}
                      multiplie={false}
                      setValue={(value) =>
                        setNewSubscription((prev) => ({
                          ...prev,
                          discount: prev.discount.map((item, i) =>
                            i === index
                              ? { ...item, for: value as string }
                              : item
                          ),
                        }))
                      }
                      value={item.for}
                      height="0px"
                      padding="12px 20px"
                    />
                  </div>
                  <div>
                    <Input
                      inputValue={item.amount.toString()}
                      onChangeInput={(value) =>
                        setNewSubscription((prev) => ({
                          ...prev,
                          discount: prev.discount.map((item, i) =>
                            i === index
                              ? { ...item, amount: Number(value) }
                              : item
                          ),
                        }))
                      }
                      isVisible
                      required
                      type="number"
                      maxNumber={100}
                      minNumber={0}
                    />
                  </div>
                  <CloseIcon
                    className={s.deleteDiscountIcon}
                    onClick={() =>
                      setNewSubscription((prev) => ({
                        ...prev,
                        discount: prev.discount.filter(
                          (el, elIndex) => elIndex !== index
                        ),
                      }))
                    }
                  />
                </div>
              ))}

              <PlusIcon
                className={s.plusDiscountIcon}
                onClick={() =>
                  setNewSubscription((prev) => ({
                    ...prev,
                    discount: [...prev.discount, { for: "", amount: 0 }],
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className={s.rightBlock}>
          <h2>Info for users</h2>
          {subscriptionInfo.map((item, index) => (
            <div className={s.infoCardElement} key={index}>
              <div className={s.infoCardBlock}>
                <SubscriptionInfoCard
                  description={item.description}
                  icon={item.icon}
                  index={index}
                  isFeatures={false}
                  setInfoCard={setSubscriptionInfo}
                  title={item.title}
                  selectedLanguages={selectedLanguages}
                />
                <div className={s.deleteFeaturesBlock}>
                  <CloseIcon
                    className={s.deleteFeaturesBtn}
                    onClick={() => {
                      subscriptionInfo.length > 1
                        ? setSubscriptionInfo((prev) =>
                            prev.filter((el, elIndex) => elIndex !== index)
                          )
                        : Notify("There must be at least 1 element");
                    }}
                  />
                </div>
              </div>
              <AnimateHeight
                height={openCards.includes(index) ? "auto" : 0}
                className={s.featureList}
              >
                <div className={s.featuresListBlock}>
                  {item.features.map((feature, i) => (
                    <div>
                      <div className={s.featturesElementBlock}>
                        <SubscriptionInfoCard
                          description={feature.description}
                          icon={feature.icon}
                          index={index}
                          isFeatures={true}
                          setInfoCard={setSubscriptionInfo}
                          title={feature.title}
                          selectedLanguages={selectedLanguages}
                          featureIndex={i}
                        />
                        <div className={s.deleteFeaturesBlock}>
                          <CloseIcon
                            className={s.deleteFeaturesBtn}
                            onClick={() => {
                              item.features.length > 1
                                ? removeFeature(index, i)
                                : Notify("There must be at least 1 element");
                            }}
                          />
                        </div>
                      </div>
                      {i === item.features.length - 1 && (
                        <div className={s.plusFeaturesBtnBlock}>
                          <PlusIcon
                            className={s.plusCardBtn}
                            onClick={() => addNewFeature(index)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </AnimateHeight>
              <div className={s.openCardBtn}>
                <ChevronDown
                  className={
                    openCards.includes(index) ? s.chevronUpBtn : s.chevronBtn
                  }
                  onClick={() => {
                    openCards.includes(index)
                      ? setOpenCards((prev) =>
                          prev.filter((el) => el !== index)
                        )
                      : setOpenCards((prev) => [...prev, index]);
                  }}
                />
              </div>
            </div>
          ))}
          <div className={s.plusCardBtnBlock}>
            <PlusIcon
              className={s.plusCardBtn}
              onClick={() => {
                setSubscriptionInfo((prev) => [
                  ...prev,
                  subscriptionInfoInitial,
                ]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewSubscription;

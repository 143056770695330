import { Tooltip } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useEffect, useState } from "react";
import SpecialistApi from "../../../api/specialistsApi";
import { ReactComponent as ViewFileIcon } from "../../../assets/Profile/specialist/view-file.svg";
import Loader from "../../../components/Loader/Loader";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import {
  EducationCertificatesResponse,
  FederationsCertificatesResponse,
  QualificationCertificatesResponse,
} from "../../../types/TypeSpecialist";
import { formatEducationPeriod } from "../../../utils/dateActions";
import s from "./EducationSection.module.css";

type EducationSectionProps = {
  specialistId: string;
  userId: string;
  isPublicProfile: boolean;
};

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

const EducationSection = ({
  specialistId,
  userId,
  isPublicProfile,
}: EducationSectionProps) => {
  const token = localStorage.getItem("token");
  const [certificatesData, setCertificatesData] = useState<CertificatesData>({
    education: undefined,
    qualification: undefined,
    federation: undefined,
  });
  const [activeTab, setActiveTab] =
    useState<keyof CertificatesData>("education");
  const [previewFile, setPreviewFile] = useState<{
    pdfFile: string | null;
    imageFile: string | null;
  }>({ pdfFile: null, imageFile: null });
  const [previewFileModal, setPreviewFileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const getEducationCertificates = async () => {
    if (!token) return;
    const response = await SpecialistApi.getEducationCertificates(
      token,
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };
  const getEducationPublicCertificates = async () => {
    const response = await SpecialistApi.getEducationPublicCertificates(
      specialistId,
      1,
      5
    );

    return { total: response.total, certificates: response.certificates };
  };
  const getFederationCertificates = async () => {
    if (!token) return;
    const response = await SpecialistApi.getFederationCertificates(
      token,
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };
  const getFederationPublicCertificates = async () => {
    const response = await SpecialistApi.getFederationPublicCertificates(
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };
  const getQualificationCertificates = async () => {
    if (!token) return;
    const response = await SpecialistApi.getQualificationCertificates(
      token,
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };
  const getQualificationPublicCertificates = async () => {
    const response = await SpecialistApi.getQualificationPublicCertificates(
      specialistId,
      1,
      5
    );
    return { total: response.total, certificates: response.certificates };
  };

  useEffect(() => {
    const makeAsync = async () => {
      setIsLoading(true);

      try {
        const getEducation = !isPublicProfile
          ? getEducationCertificates
          : getEducationPublicCertificates;

        const getFederation = !isPublicProfile
          ? getFederationCertificates
          : getFederationPublicCertificates;

        const getQualification = !isPublicProfile
          ? getQualificationCertificates
          : getQualificationPublicCertificates;
        const [educationData, federationData, qualificationData] =
          await Promise.all([
            getEducation(),
            getFederation(),
            getQualification(),
          ]);
        setCertificatesData({
          education: educationData?.certificates,
          federation: federationData?.certificates,
          qualification: qualificationData?.certificates,
        });
      } catch (error) {
        console.error("Error fetching certificates data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    makeAsync();
  }, [specialistId, userId]);

  useEffect(() => {
    setPreviewFile((prev) => ({ ...prev, imageFile: null }));
  }, []);
  const previewFileHandler = (link: string, type: string) => {
    if (type.includes("pdf")) {
      setPreviewFile({
        imageFile: null,
        pdfFile: link,
      });
    } else {
      setPreviewFile({
        imageFile: link,
        pdfFile: null,
      });
    }
    setPreviewFileModal(true);
  };

  if (isLoading) {
    return (
      <div className={s.loaderContainer}>
        <Loader size={100} />
      </div>
    );
  }
  if (
    !certificatesData.education &&
    !certificatesData.federation &&
    !certificatesData.qualification
  ) {
    return null;
  }
  return (
    <div className={s.container}>
      <div className={s.tabsBlock}>
        <div
          className={activeTab === "education" ? s.activeTab : s.notActiveTab}
          onClick={() => setActiveTab("education")}
        >
          <span>Education</span>
          {activeTab === "education" && (
            <span className={s.activeTabUnderLine}></span>
          )}
        </div>
        <div
          className={
            activeTab === "qualification" ? s.activeTab : s.notActiveTab
          }
          onClick={() => setActiveTab("qualification")}
        >
          <span>Qualification</span>
          {activeTab === "qualification" && (
            <span className={s.activeTabUnderLine}></span>
          )}
        </div>
        <div
          className={activeTab === "federation" ? s.activeTab : s.notActiveTab}
          onClick={() => setActiveTab("federation")}
        >
          <span>Membership in federations</span>
          {activeTab === "federation" && (
            <span className={s.activeTabUnderLine}></span>
          )}
        </div>
      </div>
      <div className={s.certificateListBlock}>
        {activeTab === "education"
          ? certificatesData.education &&
            certificatesData.education.map((item, index) => (
              <div key={index} className={s.certificateElement}>
                <div className={s.headCertificateBlock}>
                  <div>
                    <span>{item.education.text}</span>
                    <span className={s.educationDescription}>
                      {item.description}
                    </span>
                  </div>
                </div>
                <div className={s.bottomCertificateBlock}>
                  <span>
                    {formatEducationPeriod(
                      new Date(item.membershipStart),
                      new Date(item.membershipEnd),
                      "en"
                    )}
                  </span>
                  <Tooltip arrow title="Show certificate">
                    <div
                      onClick={() => previewFileHandler(item.link, item.type)}
                      className={s.showCertificateBtn}
                    >
                      <ViewFileIcon />
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))
          : activeTab === "federation"
          ? certificatesData.federation &&
            certificatesData.federation.map((item, index) => (
              <div key={index} className={s.certificateElement}>
                <div className={s.headCertificateBlock}>
                  <a href={item.federation.badge.url} target="_blank">
                    <img
                      src={item.federation.badge.link}
                      alt=""
                      className={s.badgeIcon}
                    />
                  </a>
                  <span>{item.federation.text}</span>
                </div>
                <div className={s.bottomCertificateBlock}>
                  <span>
                    {formatEducationPeriod(
                      new Date(item.membershipStart),
                      new Date(item.membershipEnd),
                      "en"
                    )}
                  </span>
                  <Tooltip arrow title="Show certificate">
                    <div
                      onClick={() => previewFileHandler(item.link, item.type)}
                      className={s.showCertificateBtn}
                    >
                      <ViewFileIcon />
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))
          : certificatesData.qualification &&
            certificatesData.qualification.map((item, index) => (
              <div key={index} className={s.certificateElement}>
                <div className={s.headCertificateBlock}>
                  <a href={item.qualification.badge.url} target="_blank">
                    <img
                      src={item.qualification.badge.link}
                      alt=""
                      className={s.badgeIcon}
                    />
                  </a>
                  <span>{item.qualification.text}</span>
                </div>
                <div className={s.bottomCertificateBlock}>
                  <span>
                    {formatEducationPeriod(
                      new Date(item.membershipStart),
                      new Date(item.membershipEnd),
                      "en"
                    )}
                  </span>
                  <Tooltip arrow title="Show certificate">
                    <div
                      onClick={() => previewFileHandler(item.link, item.type)}
                      className={s.showCertificateBtn}
                    >
                      <ViewFileIcon />
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))}
      </div>
      <ModalWindow
        isOpen={previewFileModal}
        setIsOpen={setPreviewFileModal}
        bgColor="transparent"
        height="fit-content"
        width={"fit-content"}
        padding="0"
        maxWidth="fit-content"
      >
        <div
          className={
            previewFile.pdfFile ? s.pdfPreviewBlock : s.imagePreviewBlock
          }
        >
          {previewFile.pdfFile && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
              <div className={s.pdfWorker}>
                <Viewer
                  fileUrl={previewFile.pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          )}

          {previewFile.imageFile && (
            <img src={previewFile.imageFile} alt="" className={s.previewImg} />
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default EducationSection;
